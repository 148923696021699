/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { remove as r } from "../../core/arrayUtils.js";
import { meterIn as t } from "../../renderers/support/lengthUtils.js";
function e(r) {
  return !!r && null != t[r];
}
function n(r) {
  return 1 / (t[r] || 1);
}
function o() {
  const e = Object.keys(t);
  return r(e, "decimal-degrees"), e.sort(), e;
}
const s = o();
export { n as getMetersPerUnit, s as supportedUnits, e as supportsUnit };